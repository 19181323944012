<template>
  <div>
    <q-btn flat @click="handleRoute(model.path)">
      <strong>{{ model.name}} </strong>
    </q-btn>
  </div>
</template>

<script>

// Mixins
import TableMixin from './../../components/global/TableMixin'

export default {
  name: 'RouteCardNew',
  props: {
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      totalNumber: 0,
      options: {}
    }
  },
  methods: {
    handleRoute (path) {
      this.$router.push(path)
    },
  }
}
</script>
