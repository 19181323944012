<template>
  <div class="q-ma-lg row items-start q-gutter-md items-center" >
    <q-card class="my-card q-mt-sm" style="min-width: 300px; min-height: 400px">

      <q-card-section class="bg-deep-purple-7 text-white" >

        <div class="text-h6">
          Picking
          <div style="text-align: right;">
            <q-icon name="waving_hand" color="white" right-align size="32px"  />
          </div>
        </div>
        <div class="text-subtitle2">
          Queues and settings
        </div>

      </q-card-section>

      <q-card-actions vertical align="left">
          <div
              v-for="option in picking"
              :key="option.path"
          >
            <route-card-new :model="option" />
          </div>
      </q-card-actions>
    </q-card>

    <q-card class="my-card q-mt-sm" style="min-width: 300px; min-height: 400px">
      <q-card-section class="bg-blue-7 text-white">
        <div class="text-h6">
          Packing
          <div style="text-align: right;">
            <q-icon name="redeem" color="white" right-align size="32px"  />
          </div>
        </div>
        <div class="text-subtitle2">Queues settings</div>
      </q-card-section>

      <q-card-actions vertical align="left">
        <div
            v-for="option in packing"
            :key="option.path"
        >
          <route-card-new :model="option" />
        </div>
      </q-card-actions>
    </q-card>

    <q-card class="my-card q-mt-sm" style="min-width: 300px; min-height: 400px">
      <q-card-section class="bg-teal-7 text-white">
        <div class="text-h6">
          Tasks
          <div style="text-align: right;">
            <q-icon name="description" color="white" right-align size="32px"  />
          </div>
        </div>
        <div class="text-subtitle2">View and manage your tasks</div>
      </q-card-section>

      <q-card-actions vertical align="left">
        <div
            v-for="option in tasks"
            :key="option.path"
        >
          <route-card-new :model="option" />
        </div>
      </q-card-actions>
    </q-card>

    <q-card class="my-card q-mt-sm" style="min-width: 300px; min-height: 400px">
      <q-card-section class="bg-blue-grey-5 text-white">
        <div class="text-h6">
          Other Queues
          <div style="text-align: right;" >
            <q-icon name="check_circle" color="white" right-align size="32px" />
          </div>
        </div>
        <div class="text-subtitle2">Without settings</div>
      </q-card-section>

      <q-card-actions vertical align="left">
        <div
            v-for="option in other"
            :key="option.path"
        >
          <route-card-new :model="option" />
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
// Components
import RouteCardNew from '../../components/global/RouteCardNew.vue'

export default {
  name: 'Queues',
  components: {
    RouteCardNew
  },
  data () {
    return {
      options: this.$utils.router.getRouteByEntity('Queues').children,
      picking: [],
      packing: [],
      tasks: [],
      other: []
    }
  },
  mounted() {
    this.options.forEach(e=>{
      if(e.type === 'picking') {
        this.picking.push(e)
      }
      if(e.type === 'packing') {
        this.packing.push(e)
      }
      if(e.type === 'tasks') {
        this.tasks.push(e)
      }
      if(e.type === 'other') {
        this.other.push(e)
      }
    })
  }
}
</script>